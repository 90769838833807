header {
    position: fixed;
    z-index: 1000;
    top: 20px;
    left: 0;
    width: 100%;
    text-align: center;
}

nav {
    display: block;
    transition: background 1s ease;
    background: rgba(0,0,0,0.1);
    border-radius: 5px;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }

    ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-up(sm) {
            padding: 0 1em;
        }

        li.nav-item {
            display: inline-block;

            a {
                display: block;
                font-family: 'Montserrat', sans-serif;
                font-size: 12px;
                text-transform: uppercase;
                text-decoration: none;
                color: white;

                padding: 0.8em 0 0.5em;
                margin: 0 0.7em;

                border-bottom: 3px solid transparent;
                transition: border-color 0.3s ease;

                @include media-breakpoint-up(sm) {
                    font-size: 14px;
                    margin: 0 1em;
                }

                @include media-breakpoint-up(md) {
                    font-size: 16px;
                    margin: 0 1.5em;

                    &:hover {
                        border-bottom-color: white;
                    }
                }

            }

            &.nav-item--active a {
                border-bottom-color: rgb(var(--bs-primary-rgb));
            }
        }
    }
}

header.header--active {
    nav {
        background: rgba(0,0,0,0.5);
    }
}


