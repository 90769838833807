.modal {
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;

    .modal-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
    }

    .container-fluid {
        position: relative;
    }

    .modal-content {
        background: transparent;
    }

    .modal-content-margin {
        height: 100%;
        width: 100%;
    }

    .modal-content {
        position: relative;
        margin: 0 auto;
    }
}


.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 60px;
    width: 60px;
    z-index: 2000;

    transform: translateX(150%);
    transition: transform 0.4s ease;

    .close {
        fill: white;
        position: relative;
        top: 10px;
        left: 10px;
    }
}

.modal.modal--active {
    opacity: 1;
    pointer-events: auto;
    z-index:2999;


    .modal-close {
        transform: translateX(0%);
    }
}

.video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
