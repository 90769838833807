.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
}

.background-inner {
    position: relative;
    width: 100%;
    height: 100%;

    opacity: 1;
    transition: opacity 3s ease;

    &.background-inner--active {
        opacity: 0.5;
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(../assets/qsa_sky_new.jpg);
    background-size: cover;
    background-position: center;
}
