:root {
    --bs-white-rgb: 255,255,255;
    --bs-primary-rgb: 255,209,85;
}

body {
    background-color: #7a9bb4;
    color: black;

    font-family: 'EB Garamond', serif;
    font-size: 18px;
}

h1 {
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
}

h2 {
    display: inline-block;

    font-family: 'Montserrat', sans-serif;
    font-size: 14px ;
    text-transform: uppercase;

    padding-bottom: 1em;
    margin-bottom: 2em;
    border-bottom: 5px solid rgb(var(--bs-primary-rgb));
}

h3 {
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;

    margin-bottom: 1em;

    @media screen and (min-width: 360px) {
        font-size: 42px;
    }

    @include media-breakpoint-up(sm) {
        font-size: 35px;
    }
}


main {
    position: relative;
    z-index: 1;
}

.vh-90 {
    height: 90vh;
}

a.cta-link {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: #666;
    border-bottom: 2px solid #666;
    padding-bottom: 0.1em;
    transition: color 0.3s ease;

    &:hover {
        color: #000;
        border-bottom: 2px solid #000;
    }
}

.container-fluid {
    max-width: 1200px;
}

.pointer {
    cursor: pointer;
}

.full-image {
    border-bottom: 5px solid rgb(var(--bs-primary-rgb));
    display: block;
    margin: 0 auto;
}
