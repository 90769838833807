.card {
    position: relative;
    background: white;
    overflow: hidden;

    border-bottom: 5px solid #d2d2d2;
    padding: 30px 20px 80px;
    margin-bottom: 50px;

    transform: translateY(50px);
    opacity: 0;
    transition: all 0.3s ease;

    &:after {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 52px;

        content: ' ';

        background-image: url(../assets/logo_black_small.svg);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: right bottom;
        opacity: 0.2;

    }

    * {
        opacity: 0;
    }
}

.card-image {
    position: relative;
    height: 0;
    width: 50%;
    padding-bottom: 50%;
    overflow: hidden;
    display: block;



    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    @include media-breakpoint-up(sm) {
        width: 25%;
        padding-bottom: 25%;
    }
}

@keyframes slideIn {
    0% { transform: translateX(-50px); opacity: 0; }
    100% { transform: translateX(0px); opacity: 1; }
}

.card.card--active {
    transform: translateY(0px);
    opacity: 1;

    * {
        animation: slideIn 0.4s ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &:nth-child(1) { animation-delay: 0.6s; }
        &:nth-child(2) { animation-delay: 0.8s; }
        &:nth-child(3) { animation-delay: 1s; }
        &:nth-child(4) { animation-delay: 1.2s; }
        &:nth-child(5) { animation-delay: 1.4s; }
        &:nth-child(6) { animation-delay: 1.6s; }
        &:nth-child(7) { animation-delay: 1.8s; }
        &:nth-child(8) { animation-delay: 2s; }
        &:nth-child(9) { animation-delay: 2.2s; }
        &:nth-child(10) { animation-delay: 2.4s; }
        &:nth-child(11) { animation-delay: 2.6s; }
        &:nth-child(12) { animation-delay: 2.8s; }
        &:nth-child(13) { animation-delay: 3s; }
        &:nth-child(14) { animation-delay: 3.2s; }
        &:nth-child(15) { animation-delay: 3.4s; }
        &:nth-child(16) { animation-delay: 3.6s; }
        &:nth-child(17) { animation-delay: 3.8s; }
        &:nth-child(18) { animation-delay: 4s; }
    }
}

div[class*="col-"] {
    @include media-breakpoint-up(sm) {
        &:nth-child(2) {
            .card { transform: translateY(50%); }
        }
    }
    @include media-breakpoint-up(xl) {
        &:nth-child(2) {
            .card {
                transform: none;
                margin-top: 50%;
            }
        }
        &:nth-child(3) {
            .card {
                transform: none;
                top: calc(-50% + 50px);
            }
        }
    }
}
