.logo {
    width: 200px;
    display: block;
    margin: 0 auto;

    @media screen and (min-width: 340px) {
        width: 250px;
    }

    @include media-breakpoint-up(sm) {
        width: 350px;
    }

    @include media-breakpoint-up(lg) {
        width: 400px;
    }
}

.logo-small {
    width: 70px;
    position: fixed;
    top: 20px;
    left: 20px;
    pointer-events: none;

    opacity: 0;
    transform: translateY(-100px);

    transition: all 0.4s ease;

    @media screen and (min-width: 1370px) {
        &.logo-small--active {
            opacity: 1;
            transform: translateY(0px);
        }
    }

}
